<template>
  <div class="sm:grid grid-cols-3 gap-5 mx-auto px-16">
    <section class="flex">
      <blockquote class="twitter-tweet">
        <p lang="en" dir="ltr">
          User: Shane7614
          <a href="https://t.co/aU3u7qmIIZ">pic.twitter.com/aU3u7qmIIZ</a>
        </p>
        &mdash; SpaceSeats (@SeatsSpace)
        <a
          href="https://twitter.com/SeatsSpace/status/1359735758379245569?ref_src=twsrc%5Etfw"
          >February 11, 2021</a
        >
      </blockquote>
    </section>
    <section class="flex">
      <blockquote class="twitter-tweet">
        <p lang="en" dir="ltr">
          User:
          <a href="https://twitter.com/Shane?ref_src=twsrc%5Etfw">@Shane</a
          >#7614
          <a href="https://t.co/2sV4zyjVYb">pic.twitter.com/2sV4zyjVYb</a>
        </p>
        &mdash; SpaceSeats (@SeatsSpace)
        <a
          href="https://twitter.com/SeatsSpace/status/1359731566293975041?ref_src=twsrc%5Etfw"
          >February 11, 2021</a
        >
      </blockquote>
    </section>
    <section class="flex">
      <blockquote class="twitter-tweet">
        <p lang="en" dir="ltr">
          User: Shane7614
          <a href="https://t.co/1vzk7dwGHA">pic.twitter.com/1vzk7dwGHA</a>
        </p>
        &mdash; SpaceSeats (@SeatsSpace)
        <a
          href="https://twitter.com/SeatsSpace/status/1359735318178635779?ref_src=twsrc%5Etfw"
          >February 11, 2021</a
        >
      </blockquote>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://platform.twitter.com/widgets.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  }
};
</script>

<template>
  <a
    class="bg-green-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mt-4 w-1/2 text-center"
    :class="padding"
    href="https://spaceseats.io/api/discord/login"
    >{{ text }}</a
  >
</template>
<script>
export default {
  props: ["text", "padding"]
};
</script>

<template>
  <div class="">
    <div class="w-full m-auto px-16">
      <h2 class="text-3xl font-bold mb-8 text-shadow text-center">
        Premium features for premium members
      </h2>
      <!-- <p class="font-medium text-violet-100 text-shadow mb-4">
        Learn how to get tickets. Learn how to get tickets. Learn how to get
        tickets. Learn how to get tickets. Learn how to get tickets. Learn how
        to get tickets. Learn how to get tickets. Learn how to get tickets.
      </p> -->
    </div>
    <div class="sm:grid grid-cols-3 gap-5 mx-auto px-16">
      <section
        class="flex"
        v-for="fet of features"
        :key="fet.id"
        @mousemove="hover(fet.id, fet.imgName)"
        @mouseout="out(fet.id, fet.imgName)"
      >
        <div
          class="w-full relative text-white overflow-hidden rounded-3xl flex"
        >
          <div
            class="w-full flex md:flex-col bg-gray-600"
            :class="'hover' + fet.id"
          >
            <div
              class="sm:max-w-sm sm:flex-none md:w-auto md:flex-auto flex flex-col items-start relative z-10 p-6 xl:p-8"
            >
              <h2 class="text-xl font-semibold mb-2 text-shadow">
                {{ fet.heading }}
              </h2>
              <p
                class="font-extralight text-sm text-violet-100 text-shadow mb-4"
              >
                {{ fet.desc }}
              </p>
              <div class="flex items-center pt-2">
                <div
                  :id="'bgimg' + fet.id"
                  class="bg-cover bg-center w-14 h-14 rounded-full mr-3"
                  :class="'bgimg' + fet.id"
                  :style="{ backgroundImage: fet.image }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      features: [
        {
          id: 1,
          heading: "Blazing Fast Monitors",
          desc:
            "Never miss a restock or shock drop again. Spaceseats offers a collection of top-quality monitors for the most coveted websites, including Nike, Adidas, Supreme, Footsites, Off-White, Hibbett, over 100 Shopify sites and much more. We are constantly expanding the sites we monitor.",
          image: `url(${require("@/assets/svg/monitors.svg")})`,
          imgName: "monitors"
        },
        {
          id: 2,
          heading: "Dedicated Support Team",
          desc:
            "Get the help you need, whenever you need. Whether you are just starting out or are an experienced reseller, Spaceseats staff will make sure you are prepared for any drop with 24/7 assistance tailored to you via our ticket support system. Members also have access to release guides, sitelists, resell predictions and video tutorials.",
          image: `url(${require("@/assets/svg/faq.svg")})`,
          imgName: "faq"
        },
        {
          id: 3,
          heading: "Top Tier Groupbuys",
          desc:
            "Upgrade your arsenal. Spaceseats's relationships with the most prestigious sneaker-related businesses gives members the chance to access some of the most limited and desirable tools to assist your reselling, through frequent bot and proxy groupbuys.",
          image: `url(${require("@/assets/svg/partner.svg")})`,
          imgName: "partner"
        },
        {
          id: 4,
          heading: "Exclusive Information",
          desc:
            "Be prepared for every release. Spaceseats's exclusive information and early links are unparalleled. We are renowned for our insider knowledge on Nike SNKRS, Kith, Footsites, and in-store drops in major cities.",
          image: `url(${require("@/assets/svg/merch.svg")})`,
          imgName: "merch"
        },
        {
          id: 5,
          heading: "Custom Software",
          desc:
            "Tools at your fingertips. All Spaceseats members have free access to all software made by our in-house development team including an advanced autofill extension and a browser program. We also offer useful tools within the Discord server such as an eBay view bot, SMS alerts and a proxy generator.",
          image: `url(${require("@/assets/svg/trends.svg")})`,
          imgName: "trends"
        },
        {
          id: 6,
          heading: "Vast Opportunities",
          desc:
            "Expand your horizons. Spaceseats provides more opportunities than just sneakers for members to make money. Whether it’s through Sports Card Reselling, Art Reselling, Daily Fantasy Sports, Sports Betting or Funko, members are encouraged to pursue a wide range of profitable avenues.",
          image: `url(${require("@/assets/svg/profits.svg")})`,
          imgName: "profits"
        }
      ]
    };
  },
  methods: {
    hover(id, name) {
      const imgH = require("@/assets/svg/" + name + "White.svg");
      document.getElementById(
        `bgimg${id}`
      ).style.backgroundImage = `url(${imgH})`;
    },
    out(id, name) {
      const imgO = require("@/assets/svg/" + name + ".svg");
      document.getElementById(
        `bgimg${id}`
      ).style.backgroundImage = `url(${imgO})`;
    }
  }
};
</script>
<style>
.hover1:hover {
  background-color: #ee9000;
}
.hover2:hover {
  background-color: #0056cf;
}
.hover3:hover {
  background-color: #b015ff;
}
.hover4:hover {
  background-color: #e61a22;
}
.hover5:hover {
  background-color: #0056d0;
}
.hover6:hover {
  background-color: #17b400;
}
</style>

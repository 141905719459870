<template>
  <div>
    <div
      :style="{
        background:
          'url(' + require('../assets/img/landing/banner.png') + ') repeat',
        color: 'rgb(255, 255, 255)',
        paddingTop: '1px',
        height: '100vh'
      }"
    >
      <Header />

      <div class="mx-auto flex flex-wrap flex-col md:flex-row pt-40">
        <!--Left Col-->
        <div
          class="flex flex-col w-full md:w-2/5 text-center md:text-left pt-28 pl-36"
        >
          <h2 class="da-home-page-text">SpaceSeats:</h2>
          <h3 class="da-home-page-text mt-4">
            The one stop ticket broker shop
          </h3>

          <Join text="Join our comunity" padding="px-4" />
        </div>
        <!--Right Col-->
        <div class="w-full md:w-3/5 py-6 text-right">
          <img
            class="w-full md:w-4/5 z-50 banner-img"
            src="../assets/img/landing//aero2.png"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="w-full mx-auto">
        <div class="py-12">
          <Numbers />
        </div>
        <div class="py-4" id="features">
          <Features />
        </div>
        <div class="my-12">
          <Testimonials />
          <div class="text-center mt-8">
            <Join text="Join Now" padding="px-16" />
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Join from "@/components/landing/Join.vue";
import Numbers from "@/components/landing/Numbers.vue";
import Features from "@/components/landing/Features.vue";
import Testimonials from "@/components/landing/Testimonials.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Join,
    Numbers,
    Features,
    Testimonials,
    Footer
  },
  data() {
    return {
      image: ""
    };
  }
};
</script>
<style scopped>
h2.da-home-page-text {
  font-weight: 600;
  font-size: 34px;
}
h3.da-home-page-text {
  line-height: 1.5;
}
.banner-img {
  width: 200px;
  margin-left: 63%;
}

img {
  vertical-align: middle;
  border-style: none;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "../views/Landing.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: Landing },
  {
    path: "/discord",
    component: () => import("../views/DiscordFailed.vue")
  },
  {
    path: "/terms-of-service",
    name: "Terms",
    component: () => import("../views/Terms.vue")
  },
  {
    path: "/about-us",
    name: "About",
    component: () => import("../views/About.vue")
  },

  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/admin/Login.vue")
  },
  {
    path: "/admin",
    name: "Dashboard",
    component: () => import("../views/admin/Dashboard.vue"),
    children: [
      {
        path: "test/:eventName/:city/:state",
        name: "test",
        component: () => import("../views/admin/test.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "test1/:eventName",
        name: "test1",
        component: () => import("../views/admin/test1.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:
          "test2/:id/:venueid/:venuename/:city/:state/:event/:performerid/:category/:availabletixs/:totalvalue/:avgvalue/:lasteventdate/:tixsoldindaterange/:avgsaleprice/:createdat/:isactive",
        name: "test2",
        component: () => import("../views/admin/test2.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "newtest2/:id",
        name: "newtest2",
        component: () => import("../views/admin/newtest2.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:
          "test3/:id/:venueid/:venuename/:city/:state/:event/:performerid/:category/:availabletixs/:totalvalue/:avgvalue/:lasteventdate/:tixsoldindaterange/:avgsaleprice/:createdat/:isactive",
        name: "test3",
        component: () => import("../views/admin/test3.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "fb/:id/",
        name: "fb",
        component: () => import("../views/admin/fb.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "test4/:id/",
        name: "test4",
        component: () => import("../views/admin/test4.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "test5/:venuename/",
        name: "test5",
        component: () => import("../views/admin/test5.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "show_monitor",
        name: "show_monitor",
        component: () => import("../views/admin/show_monitor.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "dashboard",
        name: "Overview",
        component: () => import("../views/admin/Overview.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "tickets",
        name: "NewTickets",
        component: () => import("../views/admin/NewTickets.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ticketsshow",
        name: "Ticketsshow",
        component: () => import("../views/admin/Ticketsshow.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "unique-tickets",
        name: "UniqueTix",
        component: () => import("../views/admin/UniqueTix.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ticket-tn",
        name: "TicketTN",
        component: () => import("../views/admin/TicketTN.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "venue",
        name: "Venue",
        component: () => import("../views/admin/Venue.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "inventory-counter",
        name: "InventoryCounter",
        component: () => import("../views/admin/InventoryCounter.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "inventory-changes",
        name: "InventoryChanges",
        component: () => import("../views/admin/InventoryChanges.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ticket-chart/:eventName/:city/:category",
        name: "TicketChart",
        component: () => import("../views/admin/ViewChart.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "past",
        name: "past",
        component: () => import("../views/admin/past.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "bookmark-ticket",
        name: "BookmarkTix",
        component: () => import("../views/admin/BookmarkTix.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "discord-member",
        name: "DiscordMember",
        component: () => import("../views/admin/DiscordMember.vue"),
        meta: {
          requiresAuth: true
        },
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem("user-role") === "L1") {
            next();
          } else {
            next({ name: "Overview" });
          }
        }
      },
      {
        path: "add-operator",
        name: "AddOperator",
        component: () => import("../views/admin/AddOperator.vue"),
        meta: {
          requiresAuth: true
        },
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem("user-role") === "L1") {
            next();
          } else {
            next({ name: "Overview" });
          }
        }
      },
      {
        path: "operators-list",
        name: "Operators",
        component: () => import("../views/admin/Operators.vue"),
        meta: {
          requiresAuth: true
        },
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem("user-role") === "L1") {
            next();
          } else {
            next({ name: "Overview" });
          }
        }
      },
      {
        path: "password-list",
        name: "Passwords",
        component: () => import("../views/admin/Password.vue"),
        meta: {
          requiresAuth: true
        },
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem("user-role") === "L1") {
            next();
          } else {
            next({ name: "Overview" });
          }
        }
      },
      {
        path: "scheduler",
        name: "Scheduler",
        component: () => import("../views/admin/Scheduler.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "old-tickets",
        name: "OldTickets",
        component: () => import("../views/admin/OldTickets.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "resale",
        name: "AddResale",
        component: () => import("../views/admin/Addresale.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "resaleresale",
        name: "AddResaleResale",
        component: () => import("../views/admin/Addresaleresale.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "onsale",
        name: "Onsale",
        component: () => import("../views/admin/Offerpricing.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "venuename",
        name: "venuename",
        component: () => import("../views/admin/venuename.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "craigslist",
        name: "craigslist",
        component: () => import("../views/admin/craigslist.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "transfer_script",
        name: "transfer_script",
        component: () => import("../views/admin/transfer_script.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "favourite_events_new",
        name: "FavouriteEventsNew",
        component: () => import("../views/admin/FavouriteEventsNew.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "favourite_venues",
        name: "FavouriteVenues",
        component: () => import("../views/admin/FavouriteVenues.vue"),
        meta: {
          requiresAuth: true
        }
      },
      // {
      //   path: "favourite_events",
      //   name: "FavouriteEvents",
      //   component: () => import("../views/admin/FavouriteEvents.vue"),
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: "favourite_eventsshow",
      //   name: "FavouriteEventshow",
      //   component: () => import("../views/admin/FavouriteEventshow.vue"),
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      {
        path: "favourite_event_calendar",
        name: "FavouriteEventCalendar",
        component: () => import("../views/admin/FavouriteEventCalendar.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "chatsoptify",
        name: "chatsoptify",
        component: () => import("../views/admin/chatsoptify.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "chatsoptifylatest",
        name: "chatsoptifylatest ",
        component: () => import("../views/admin/chatsoptifylatest.vue"),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "chatsoptifyglobaldaily",
        name: "chatsoptifyglobaldaily ",
        component: () => import("../views/admin/chatsoptifyglobaldaily.vue"),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "applemusicnewrelease",
        name: "applemusicnewrelease ",
        component: () => import("../views/admin/applemusicnewrelease.vue"),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "regionalglobalweekly",
        name: "regionalglobalweekly ",
        component: () => import("../views/admin/regionalglobalweekly.vue"),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "citytoptrackindianapolisweekly",
        name: "citytoptrackindianapolisweekly ",
        component: () =>
          import("../views/admin/citytoptrackindianapolisweekly.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  { path: "/404", component: NotFound },
  { path: "*", redirect: "/404" }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem("token");
    if (token && token != "undefined") {
      next();
      return;
    }
    next("/admin");
  } else {
    next();
  }
});

export default router;

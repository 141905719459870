import Vue from "vue";
import axios from "axios";
import cheerio from "cheerio";
import VueCookies from "vue-cookies";
import jQuery from "jquery";
import dt from "datatables";
import VCalendar from "v-calendar";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import LazyLoading from "vue-lazy-loading";
import VueViewer from 'v-viewer'
import * as VueGoogleMaps from "vue2-google-maps"
import Pagination from "vue-pagination-2"

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// import { SchedulePlugin, Day, Week, WorkWeek, Month, Agenda } from "@syncfusion/ej2-vue-schedule";
// Vue.use(SchedulePlugin);

Vue.config.productionTip = false;
// Vue.use(IconsPlugin)
// Vue.use(BootstrapVue)

Vue.use(Vuetify);
Vue.use(VCalendar, {});
Vue.use(LazyLoading);
Vue.use(VueViewer);
Vue.use(Pagination);

Vue.prototype.$axios = axios;
Vue.prototype.$cookies = VueCookies;
Vue.prototype.jQuery = jQuery;
Vue.prototype.dt = dt;
Vue.prototype.$cheerio = cheerio;

Vue.prototype.$api = "https://spaceseats.io/api";
//Vue.prototype.$api = "http://localhost:9000/api";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDaO-jjmk06YaFSmT3d2YvvxD_fQYIfv3w",
    libraries: "places"
  }
});
new Vue({
  vuetify: new Vuetify(),
  router,
  store,
  render: (h) => h(App),
  data: {
    cats: [],
    currentSort: "name",
    currentSortDir: "asc",
  },
}).$mount("#app");

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{style:({
      background:
        'url(' + require('../assets/img/landing/banner.png') + ') repeat',
      color: 'rgb(255, 255, 255)',
      paddingTop: '1px',
      height: '100vh'
    })},[_c('Header'),_c('div',{staticClass:"mx-auto flex flex-wrap flex-col md:flex-row pt-40"},[_c('div',{staticClass:"flex flex-col w-full md:w-2/5 text-center md:text-left pt-28 pl-36"},[_c('h2',{staticClass:"da-home-page-text"},[_vm._v("SpaceSeats:")]),_c('h3',{staticClass:"da-home-page-text mt-4"},[_vm._v(" The one stop ticket broker shop ")]),_c('Join',{attrs:{"text":"Join our comunity","padding":"px-4"}})],1),_vm._m(0)])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"w-full mx-auto"},[_c('div',{staticClass:"py-12"},[_c('Numbers')],1),_c('div',{staticClass:"py-4",attrs:{"id":"features"}},[_c('Features')],1),_c('div',{staticClass:"my-12"},[_c('Testimonials'),_c('div',{staticClass:"text-center mt-8"},[_c('Join',{attrs:{"text":"Join Now","padding":"px-16"}})],1)],1)])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full md:w-3/5 py-6 text-right"},[_c('img',{staticClass:"w-full md:w-4/5 z-50 banner-img",attrs:{"src":require("../assets/img/landing//aero2.png")}})])
}]

export { render, staticRenderFns }
<template>
  <nav id="header" class="fixed w-full z-10 top-0">
    <div
      id="progress"
      class="h-1 z-20 top-0"
      style="background:linear-gradient(to right, #4dc0b5 var(--scroll), transparent 0);"
    ></div>

    <div
      class="w-full md:max-w-6xl mx-auto flex flex-wrap items-center justify-between mt-0 py-3"
    >
      <div class="pl-4">
        <a
          class=" text-base no-underline hover:no-underline font-extrabold text-xl"
          href="/"
        >
          <img src="../assets/img/landing/logo1.png" />
        </a>
      </div>

      <div class="block lg:hidden pr-4">
        <button
          id="nav-toggle"
          class="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600  hover:border-green-500 appearance-none focus:outline-none"
        >
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>

      <div
        class="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-gray-100 md:bg-transparent z-20"
        id="nav-content"
      >
        <ul class="list-reset lg:flex justify-end flex-1 items-center">
          <li class="mr-3">
            <a class="inline-block py-2 px-4  no-underline" href="/">Home</a>
          </li>
          <li class="mr-3">
            <a
              class="inline-block  no-underline  hover:text-underline py-2 px-4"
              href="#features"
              >Features</a
            >
          </li>
          <li class="dropdown mr-3">
            <a
              class="inline-block  no-underline  hover:text-underline py-2 px-4"
              href="#"
              >Services</a
            >
            <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
              <li class="">
                <a
                  class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  href="http://spaceoptionstrading.com/"
                  target="_"
                  >Space Options Trading</a
                >
              </li>
              <li class="">
                <a
                  class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  href="http://spacesneaks.com/"
                  target="_"
                  >Space Sneaks</a
                >
              </li>
              <li class="">
                <a
                  class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  href="http://spacewager.com/"
                  target="_"
                  >Space Wager</a
                >
              </li>
              <li class="">
                <a
                  class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  href="http://spacefba.com/"
                  target="_"
                  >Spacefba</a
                >
              </li>
              <li class="">
                <a
                  class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                  href="http://spaceebay.com/"
                  target="_"
                  >Spaceebay</a
                >
              </li>
            </ul>
          </li>
          <li class="mr-3">
            <a
              class="inline-block  no-underline  hover:text-underline py-2 px-4"
              href="terms-of-service"
              >Terms of Services</a
            >
          </li>
          <li class="mr-3">
            <a
              class="inline-block  no-underline  hover:text-underline py-2 px-4"
              href="https://spaceseats.io/api/discord/login"
              >Login</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  mounted() {
    /* Progress bar */
    let scrollpos = window.scrollY;
    let header = document.getElementById("header");
    let navcontent = document.getElementById("nav-content");

    document.addEventListener("scroll", function() {
      /*Apply classes for slide in bar*/
      scrollpos = window.scrollY;

      if (scrollpos > 10) {
        header.classList.add("bg-white");
        header.classList.add("shadow");
        navcontent.classList.remove("bg-gray-100");
        navcontent.classList.add("bg-white");
        navcontent.classList.add("text-gray-800");
      } else {
        header.classList.remove("bg-white");
        header.classList.remove("shadow");
        navcontent.classList.remove("bg-white");
        navcontent.classList.add("bg-gray-100");
        navcontent.classList.remove("text-gray-800");
      }
    });

    //Javascript to toggle the menu
    document.getElementById("nav-toggle").onclick = function() {
      document.getElementById("nav-content").classList.toggle("hidden");
    };
    // Add background image in body tag
    // let body = document.getElementsByTagName("body")[0];
    // body.style.background = `url(${require("@/assets/img/landing/banner.png")}) repeat`;
    // body.style.color = "#fff";
  }
};
</script>
